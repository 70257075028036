import { Box, Button, EmailSVG, Flex, Heading } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ErrorMessage, Form, Input, PasswordInput } from "@/app/components";

import { LoginViewProps } from "./login.types";

export const LoginView: React.FC<LoginViewProps> = ({ handleLogin }) => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        minHeight: "100vh",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          opacity: 0.1,
          left: 0,
          right: 0,
          top: 0,
          zIndex: -1,
          bottom: 0,
          background: "url('/assets/loginBackground.svg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: ["auto", null, null, null, null, "cover"],
        }}
      />
      <Flex
        sx={{
          width: "350px",
          boxShadow: `0 0 5px var(--theme-ui-colors-shadow)`,
          maxWidth: "760px",
          borderRadius: "16px",
          backdropFilter: "blur(6px)",
          py: 4,
        }}
      >
        <Flex
          sx={{
            px: 4,
            py: 4,
            flex: 1,
            flexBasis: "0px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading
            as="h4"
            sx={{
              textAlign: "center",
              fontSize: [3, 4],
              mb: 2,
            }}
          >
            {t("TraceX Admin Panel")}
          </Heading>

          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(t("Username is required")),
              password: Yup.string().required(t("Password is required")),
            })}
            onSubmit={handleLogin}
            validateOnMount
          >
            {({ handleSubmit, isSubmitting, isValid, status, setFieldValue, submitForm, values }) => (
              <>
                {!!status && <Box sx={{ color: "yellow", mt: 3, textAlign: "center" }}>{t(status)}</Box>}
                <Form onSubmit={handleSubmit} sx={{ mt: 4, fontSize: 1 }}>
                  <Form.Item>
                    <Input
                      name="username"
                      placeholder={t("Username")}
                      autoCapitalize="none"
                      prefix={{
                        component: (
                          <Flex
                            sx={{
                              height: "18px",
                              width: "18px",
                            }}
                          >
                            <EmailSVG width="18" color="text" />
                          </Flex>
                        ),
                      }}
                      onChange={(e) => {
                        setFieldValue("username", e.currentTarget.value);
                      }}
                      sx={{ backgroundColor: "background" }}
                      value={values.username}
                      autoComplete="username"
                    />
                    <ErrorMessage name="username" sx={{ mt: 1, ml: 2 }} />
                  </Form.Item>

                  <Form.Item>
                    <PasswordInput
                      name="password"
                      placeholder={t("Password")}
                      sx={{
                        backgroundColor: "background",
                      }}
                      onInput={(e) => {
                        setFieldValue("password", e.currentTarget.value);
                      }}
                      autoComplete="current-password"
                    />
                    <ErrorMessage name="password" sx={{ mt: 1, ml: 2 }} />
                  </Form.Item>
                  <Flex
                    sx={{
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="primary"
                      sx={{ width: "75%", display: "block", mb: 3 }}
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                    >
                      {t("Sign in")}
                    </Button>
                  </Flex>
                </Form>
              </>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
};
