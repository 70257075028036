/** @jsxImportSource theme-ui */
import { Box, Button, EyeSVG, Flex, LockSVG, NoEyeSVG } from "@powerledger/ui-component-lib";
import { FC, useCallback, useState } from "react";

import { Input } from "../../input/input";
import { PasswordInputProps, StrengthResult } from "./password-input.types";
import { PasswordStrengthBar } from "./password-strength-bar";

export const checkStrength = (password: any) => {
  let standardStrength = 0;
  let extraStrength = 0;
  const missingRequirements = [];

  const specialCharacters = /[!~`@#$%^&*(),.?":{}|<>]/;
  const letters = /[a-z]/;
  const capitalLetters = /[A-Z]/;
  const numbers = /[0-9]/;

  if (!password) {
    return {
      strength: {
        standardStrength: 0,
        extraStrength: 0,
      },
      missingRequirements: [],
      length: 0,
    };
  }

  // Password Requirments
  password.length >= 12 ? standardStrength++ : missingRequirements.push("Minimum 12 characters");
  letters.test(password) ? standardStrength++ : missingRequirements.push("Include a lower case letter");
  numbers.test(password) ? standardStrength++ : missingRequirements.push("Include a number");
  capitalLetters.test(password) ? standardStrength++ : missingRequirements.push("Include a capital letter");
  specialCharacters.test(password) ? standardStrength++ : missingRequirements.push("No special characters found");

  // Great Password condition
  password.length >= 18 && extraStrength++;

  return {
    strength: {
      standardStrength: standardStrength,
      extraStrength: extraStrength,
    },
    missingRequirements: missingRequirements,
    length: password.length,
  };
};

export const PasswordInput: FC<PasswordInputProps> = ({
  name,
  placeholder,
  disabled,
  value,
  showStrength = false,
  action,
  onInput,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordShown = useCallback(() => setShowPassword((showPassword) => !showPassword), []);

  const [strengthResults, setStrengthResults] = useState<StrengthResult>({
    strength: {
      standardStrength: 0,
      extraStrength: 0,
    },
    missingRequirements: [""],
    length: 0,
  });

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Input
        {...props}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          showStrength &&
            setStrengthResults(() => checkStrength(event?.currentTarget ? event?.currentTarget?.value : ""));
          onInput && onInput(event);
        }}
        disabled={disabled}
        type={showPassword ? "text" : "password"}
        sx={{
          color: strengthResults.strength.standardStrength < 5 && showStrength && "error.500",
          "&::-ms-reveal, &::-ms-clear": {
            display: "none",
          },
          ...props.sx,
        }}
        prefix={{
          component: (
            <Flex
              sx={{
                height: "18px",
                width: "18px",
                justifyContent: "center",
                alignItems: "center",
                svg: {
                  path: {
                    fill: "text",
                  },
                },
              }}
            >
              <LockSVG height="18" />
            </Flex>
          ),
        }}
        suffix={{
          component: (
            <Button
              aria-label="show-password"
              tabIndex={-1}
              type="button"
              sx={{
                height: "18px",
                width: "18px",
                svg: {
                  path: {
                    stroke: "text",
                  },
                },
              }}
              variant="text"
              onClick={togglePasswordShown}
            >
              {showPassword ? <NoEyeSVG height="18" /> : <EyeSVG height="18" />}
            </Button>
          ),
        }}
        aria-label="password-input"
        autoCapitalize="none"
      />
      {showStrength && <PasswordStrengthBar strengthResult={strengthResults} />}
    </Box>
  );
};

export default PasswordInput;
